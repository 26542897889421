import React from 'react';
import { Link } from 'gatsby';
import Layout from '../components/layout';
import SEO from '../components/seo';

const NotFoundPage = () => (
  <Layout>
    <SEO title="Page Not Found" />
    <div className="text-wrapper">
      <h1>Wrong Page</h1>
      <p>
        BABOWWW! Sorry but the EarFest page you’re looking for couldn’t be found.
        Please follow your ears back to the&nbsp;
        <Link to="/">home page</Link>
        &nbsp;
        or contact&nbsp;
        <a href="mailto:Production@eardrum.com.au">Production@eardrum.com.au</a>
        .
      </p>
    </div>
  </Layout>
);

export default NotFoundPage;
